<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout.vue";

const route = useRoute();

// Dynamically retrieve layout props from route meta
const layoutProps = computed(() => route.meta.layoutProps || {});
</script>

<template>
  <component :is="$route.meta.layout || DefaultLayout" v-bind="layoutProps">
    <router-view />
  </component>
</template>
