import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";
import type { Component } from "vue";
import Home from "../views/HomeView.vue";
import { ROUTES } from "@/models/routes";

declare module "vue-router" {
  interface RouteMeta {
    // allowed Roles for the route, "ADMIN" is always allowed
    ROLES?: string[];
    layout?: Component;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTES.HOME.path,
    name: ROUTES.HOME.name,
    component: Home,
    meta: {
      layoutProps: {
        loadingShimmerType: "center",
      },
    },
  },
  {
    path: ROUTES.COMPARE.path,
    name: ROUTES.COMPARE.name,
    component: () => import("../views/CompareView.vue"),
  },
  {
    path: ROUTES.TEAM.path,
    name: ROUTES.TEAM.name,
    component: () => import("../views/TeamView.vue"),
  },
  {
    path: ROUTES.SOURCES.path,
    name: ROUTES.SOURCES.name,
    component: () => import("../views/SourcesView.vue"),
  },
  {
    path: ROUTES.ABOUT.path,
    name: ROUTES.ABOUT.name,
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/assets",
    name: "Assets",
    component: () => import("../views/AssetsView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.afterEach((to) => {
  document.body.className = to.name
    ? `page-${to.name.toString().toLowerCase()}`
    : "";
});

export default router;
