// Constants for routes
export const ROUTES = {
  HOME: {
    name: "Home",
    path: "/",
  },
  COMPARE: {
    name: "Compare",
    path: "/compare",
  },
  TEAM: {
    name: "Team",
    path: "/team",
  },
  SOURCES: {
    name: "Sources",
    path: "/sources",
  },
  ABOUT: {
    name: "About",
    path: "/about",
  },
};
