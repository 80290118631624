import { COUNTRY_IMAGE_URLS } from "@/stores/countryImages";

export const getBaseStrapiUrl = () => {
  if (document.URL.startsWith("http://localhost")) {
    return "http://localhost:1337";
  } else if (document.URL.startsWith("https://aipolicy.ius.uzh.ch")) {
    return "https://aipolicy-backend.ius.uzh.ch";
  }
};
export const getStrapiAdminUrl = () => {
  return getBaseStrapiUrl() + "/admin";
};

export const isProduction = () => {
  return document.URL.startsWith("https://aipolicy.ius.uzh.ch");
};

export const BREAKPOINTS = {
  small: 768, // same as phone breakpoint in variables.scss
  large: 1400,
};

export const getUrlByCountryCode = (countryCode: string) => {
  return COUNTRY_IMAGE_URLS[countryCode as keyof typeof COUNTRY_IMAGE_URLS];
};
