<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import "leaflet/dist/leaflet.css";
import { type LatLngExpression, type Map } from "leaflet";
import L from "leaflet";

import { BREAKPOINTS } from "@/services/Helpers";
import { ROUTES } from "@/models/routes";
import COUNTRY_CODES from "@/assets/json/ISO3166-1.countries.json";
import { useApiStore } from "@/stores/useApiStore";

const apiStore = useApiStore();
const map = ref<L.Map | null>(null);
const router = useRouter();
type Marker = {
  countryCode: string;
  countryName: string;
  coordinates: LatLngExpression;
  iconUrl: string;
};
let markerData: Marker[] = [];
apiStore.data.policies.forEach((policy) => {
  markerData.push({
    countryCode: policy.Country,
    countryName: COUNTRY_CODES[policy.Country as keyof typeof COUNTRY_CODES],
    coordinates: [policy.CenterPoint.Latitude, policy.CenterPoint.Longitude], // [policy.latitude, policy.longitude],
    iconUrl: policy.imageUrl,
  });
});

const addMarkers = () => {
  markerData.forEach((marker) => {
    const customIcon = L.icon({
      iconUrl: marker.iconUrl,
      iconSize: [32, 32],
      iconAnchor: [16, 32], // Center the icon
    });

    const leafletMarker = L.marker(marker.coordinates as LatLngExpression, {
      icon: customIcon,
    }).addTo(map.value as L.Map);

    leafletMarker.on("click", () => {
      apiStore.data.selectedCountries = [];
      apiStore.data.selectedCountries.push({
        name: marker.countryName,
        code: marker.countryCode,
      });
      router.push(ROUTES.COMPARE.path);
    });
  });
};

onMounted(() => {
  let settings = {
    center: [20, 14] as LatLngExpression, // initial center
    zoom: 2,
    minZoom: 1,
    maxZoom: 5,
  };
  if (window.innerWidth <= BREAKPOINTS.small) {
    settings = {
      center: [20, 0] as LatLngExpression, // initial center
      zoom: 1,
      minZoom: 1,
      maxZoom: 5,
    };
  }
  const bounds = L.latLngBounds(
    L.latLng(-85, -180), // Southwest
    L.latLng(85, 180), // Northeast
  );
  map.value = L.map("world-map", settings).setMaxBounds(bounds);

  // OpenStreetMap tiles
  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    attribution: "&copy; OpenStreetMap contributors",
  }).addTo(map.value as Map);

  // dark tiles from CartoDB
  /*
  L.tileLayer("https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png", {
    attribution:
      "&copy; <a href='https://www.carto.com/'>CartoDB</a> contributors",
  }).addTo(map.value as Map);*/

  addMarkers();
});
</script>

<template>
  <div id="world-map"></div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.world-map {
  width: 100%;
  margin: 0 auto;

  max-width: $home-card-max-width;
}
#world-map {
  border: 1px solid white;
  border-radius: 8px;
  overflow: hidden;
  height: 400px;
  width: 100%;
}
@media (max-width: $phone-breakpoint) {
  #world-map {
    height: 300px;
  }
}

.leaflet-marker-icon:hover {
  filter: brightness(150%);
}
.leaflet-marker-icon:hover {
  //transform: scale(1.2) !important; /* Scale up by 20% */
}
.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(195deg) brightness(99%) contrast(93%);
}
.leaflet-control-attribution,
.leaflet-control-attribution a {
  font-size: 12px !important;
  filter: none;
  background-color: transparent !important;
  color: white !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  &:hover:not(.leaflet-disabled) {
    color: black !important;
  }
}
.content a:hover {
  text-decoration: none !important;
}
</style>
