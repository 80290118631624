<script setup lang="ts">
import { onMounted } from "vue";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import TextShimmer from "@/components/TextShimmer.vue";

import { useApiStore } from "@/stores/useApiStore";

const apiStore = useApiStore();

defineProps({
  loadingShimmerType: {
    type: String,
    default: "left",
  },
});

onMounted(async () => {
  try {
    await apiStore.initialLoad();
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <PageHeader />

  <div class="content">
    <div class="content-container">
      <div style="" v-if="apiStore.data.state === 'loading'">
        <TextShimmer
          class="shimmer-container"
          v-if="loadingShimmerType === 'left'" />
        <div
          v-if="loadingShimmerType === 'center'"
          style="max-width: 800px; margin: 0 auto">
          <TextShimmer />
        </div>
      </div>
      <div v-else-if="apiStore.data.state === 'ready'">
        <slot></slot>
      </div>
      <div v-else-if="apiStore.data.state === 'error'">
        <p class="text-error">
          An unknown error has occured. Please try again later.
        </p>
      </div>
    </div>
  </div>

  <PageFooter />
</template>

<style lang="scss">
@import "@/assets/variables.scss";
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background: linear-gradient(
    135deg,
    $background-gradient-1 0%,
    $background-gradient-2 30%,
    $background-gradient-2 60%,
    $background-gradient-3 82%,
    $background-gradient-4 100%
  );
}

.content {
  flex-grow: 1;
  max-width: $max-width;
  //padding: 0 16px 16px 16px;

  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 48px;

  a {
    color: $text-color;
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: underline !important;
    color: white;
  }
}

@media (min-width: $max-width) {
  .content {
    min-width: calc($max-width - 24px);
    margin: 0 auto;
  }
}

.content-container {
  color: $text-color;
  border-radius: 16px;
}
@media (max-width: $phone-breakpoint) {
  .content-container {
    padding: 0x 24px 24px 24px;
  }
}
.text-error {
  text-align: center;
  @include medium-normal;
  color: white;
}
.shimmer-container {
  max-width: 80vw;
}
.page__title {
  //display: none;
  color: $light-blue;
  @include very-large-normal;
}
* {
  // border: 1px solid red !important;
}
</style>
